import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as styles from './NavLinks.module.css'

const NavLinks = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicSettings {
        nodes {
          data {
            body {
              ... on PrismicSettingsDataBodyMenu {
                primary {
                  label
                  link {
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const menuItems = data.allPrismicSettings.nodes[0].data.body.map(
    (item, index) => {
      return (
        <li key={index}>
          <a
            className="link_effect"
            href={`/${
              item.primary.link.slug.includes('home')
                ? ''
                : item.primary.link.slug
            }`}
          >
            {item.primary.label}
          </a>
        </li>
      )
    },
  )
  return <ul className={styles.menu_list}>{menuItems}</ul>
}

export default NavLinks
