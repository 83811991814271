import React from 'react'
import NavLinks from './NavLinks'
import { CloseX } from '../../helpers/icons'
import * as styles from './MenuModal.module.css'

const MenuModal = (props) => {
  const closeModal = () => {
    props.state(false)
    const myBody = document.querySelector('body')
    myBody.style.overflow = 'auto'
  }

  return (
    <div className={styles.modal}>
      <div className="container tar">
        <button className={styles.close} onClick={closeModal}>
          <CloseX />
        </button>
      </div>
      <NavLinks />
    </div>
  )
}

export default MenuModal
