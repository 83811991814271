import * as React from 'react'
import { Link } from 'gatsby'
import * as styles from './Header.module.css'
import { Logo, Hamburger } from '../../helpers/icons.js'

const Header = (props) => {
  const openModal = () => {
    props.state(true)
    const myBody = document.querySelector('body')
    myBody.style.overflow = 'hidden'
  }

  return (
    <header className={`${styles.header} container`}>
      <span className={`${styles.span} header_span`} />
      <Link className="logo" to="/">
        <Logo />
      </Link>
      <div className={styles.hamburger}>
        <button className={styles.button} onClick={openModal}>
          {<Hamburger />}
        </button>
      </div>
    </header>
  )
}

export default Header
