import * as React from 'react'
import SocialIcons from '../elements/SocialIcons'
import * as styles from './Footer.module.css'

const Footer = () => {
  return (
    <footer className={`${styles.footer} container footer_wrapper`}>
      <p className={styles.copyright_text}>
        © Copyright 2022. All Rights Reserved –{' '}
        <a
          className={`${styles.link} link_effect`}
          target="_blank"
          href="https://www.linkedin.com/in/stefanos-stamoulis-439975190/"
        >
          Designed and Developed by stdev
        </a>
      </p>
      <SocialIcons styles={styles} />
    </footer>
  )
}

export default Footer
